import { Component, ElementRef, inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, from, Observable } from 'rxjs';
import { filter, first, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { ScanNotificationService } from '../scan-notification.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { TextButtonComponent } from '../../button/text-button/text-button.component';
import { ScannerResultsComponent } from '../scanner-results/scanner-results.component';
import { CustomSelectComponent } from '../../form/custom-select/custom-select.component';
import { TranslocoModule } from '@jsverse/transloco';
import { AsyncPipe, NgIf } from '@angular/common';
import { ZbarAdapter } from '@lobos/scanner-adapter-zbar';
import { ArticleService } from '../../../services/catalog/article.service';
import { MatDialog } from '@angular/material/dialog';
import { SuedoArticle } from '../../../interfaces/suedo-article.interface';

@UntilDestroy()
@Component({
  selector: 'app-scanner',
  templateUrl: './scanner.component.html',
  styleUrls: ['./scanner.component.scss'],
  standalone: true,
  imports: [NgIf, TranslocoModule, CustomSelectComponent, ScannerResultsComponent, TextButtonComponent, AsyncPipe],
})
export class ScannerComponent implements OnInit, OnDestroy {
  @ViewChild('preview', { static: true })
  videoContainer: ElementRef<HTMLDivElement> | undefined;

  private scannerAdapter = inject(ZbarAdapter);
  private dialog = inject(MatDialog);
  private scanNotification = inject(ScanNotificationService);
  private articleService = inject(ArticleService);

  public scannerReady$: Observable<boolean> = this.scannerAdapter.ready();
  public article: SuedoArticle | undefined;

  private startScan$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private scannerStream$: Observable<string> | undefined;
  public enabled: boolean = true;

  selectedCamera: MediaDeviceInfo | undefined;
  _cameras: MediaDeviceInfo[] | undefined;
  cameras$: Observable<
    {
      key: string | number;
      value: string;
    }[]
  > = this.getVideoDevices().pipe(
    tap((devices: MediaDeviceInfo[]) => (this._cameras = devices)),
    map((devices: MediaDeviceInfo[]) =>
      devices.map((device: MediaDeviceInfo) => ({
        key: device.deviceId,
        value: device.label,
      })),
    ),
  );

  constructor() {
    this.startScan$
      .pipe(
        filter((start: boolean) => start),
        switchMap(() =>
          this.scannerStream$
            ? this.scannerStream$
            : (this.scannerStream$ = this.scannerAdapter.start(this.videoContainer?.nativeElement as HTMLDivElement, this.selectedCamera)),
        ),
        // catchError((error: Error) => of(!this.scannerAdapter.handleError(error))),
        filter((searchTerm: string | boolean) => !!searchTerm && this.enabled),
        tap(() => this.scanNotification.play()),
        tap(() => this.startScan$.next(false)),
        tap(() => (this.enabled = false)),
        mergeMap((searchTerm: string | boolean) =>
          this.articleService.getArticleByIdOrEan(searchTerm.toString().replace(/\s/g, '')).result$.pipe(map((result) => result.data)),
        ),
      )
      .subscribe((article: SuedoArticle | undefined) => {
        this.article = article;
      });
  }

  public ngOnInit(): void {
    if (!this.videoContainer?.nativeElement) {
      return;
    }

    this.startScan$.next(true);
  }

  public rescan(): void {
    this.article = undefined;
    this.startScan$.next(true);
    this.enabled = true;
  }

  public stop(): void {
    this.scannerStream$ = undefined;
    this.scannerAdapter.stop();
    this.startScan$.next(false);
    this.dialog.closeAll();
  }

  selectCamera(id: string | number) {
    this.selectedCamera = this._cameras?.find((camera: MediaDeviceInfo) => camera.deviceId === id);
    this.scannerStream$ = undefined;
    this.scannerAdapter.stop();
    this.startScan$.next(true);
  }

  ngOnDestroy(): void {
    this.scannerAdapter.stop();
  }

  public getVideoDevices(): Observable<MediaDeviceInfo[]> {
    return from(navigator.mediaDevices.enumerateDevices()).pipe(
      first(),
      map((devices: MediaDeviceInfo[]) => devices.filter((device: MediaDeviceInfo) => ['video', 'videoinput'].includes(device.kind))),
      map((devices: MediaDeviceInfo[]) =>
        devices.map((device: MediaDeviceInfo, index: number) => {
          const kind = 'videoinput';
          const deviceId = device.deviceId || (device as any).id;
          const label = device.label || `Video device ${index}`;
          const groupId = device.groupId;

          return {
            ...device,
            deviceId,
            label,
            kind,
            groupId,
          };
        }),
      ),
    );
  }
}
