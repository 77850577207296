import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ActivatedRoute, ParamMap, Router, RouterOutlet } from '@angular/router';
import { BreadcrumbsService } from './services/breadcrumb/breadcrumb.service';
import { PageMetaService } from './services/page-meta/services/page-meta.service';
import { TemplateRendererFactory } from '@lobos/label-print-v3';
import { Suedo3x8QrRenderer } from './services/label-print/renderer/suedo3x8_qr.renderer';
import { Suedo3x10QrRenderer } from './services/label-print/renderer/suedo3x10_qr.renderer';
import { Suedo3x11QrRenderer } from './services/label-print/renderer/suedo3x11_qr.renderer';
import { Suedo3x17QrRenderer } from './services/label-print/renderer/suedo3x17_qr.renderer';
import { environment } from '../environments/environment';
import { filter, map, tap } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { LoginModalComponent } from './components/login-modal/login-modal.component';
import { PasswordResetModalComponent } from './components/password-reset-modal/password-reset-modal.component';
import { DOCUMENT } from '@angular/common';
import { isPlatformBrowserProvider } from './providers/is-platform-browser.provider';
import { DomHelperService } from './services/dom/dom-helper.service';
import { fromEvent } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  standalone: true,
  imports: [RouterOutlet],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  route = inject(ActivatedRoute);
  router = inject(Router);
  dialog = inject(MatDialog);
  isBrowser = isPlatformBrowserProvider();
  document = inject(DOCUMENT);

  /**
   * This component has the sole purpose,
   * to initialize the breadcrumb.
   *
   * If the breadcrumb would only be initialized on the template
   * level, it might be too late for listening to the route.
   */
  breadcrumbService = inject(BreadcrumbsService);
  pageMetaService = inject(PageMetaService);

  labelPrintTemplateRendererFactory = inject(TemplateRendererFactory);
  suedo3x8QrRenderer = inject(Suedo3x8QrRenderer);
  suedo3x10QrRenderer = inject(Suedo3x10QrRenderer);
  suedo3x11QrRenderer = inject(Suedo3x11QrRenderer);
  suedo3x17QrRenderer = inject(Suedo3x17QrRenderer);

  constructor() {
    console.log('Version: ', environment.version);

    this.labelPrintTemplateRendererFactory.register(this.suedo3x8QrRenderer);
    this.labelPrintTemplateRendererFactory.register(this.suedo3x10QrRenderer);
    this.labelPrintTemplateRendererFactory.register(this.suedo3x11QrRenderer);
    this.labelPrintTemplateRendererFactory.register(this.suedo3x17QrRenderer);

    this.document.getElementById('appFavicon')!.setAttribute('href', `/assets/images/favicon_${environment.api.webshopId}.ico`);
    this.document.getElementById('appIcon')!.setAttribute('href', `/assets/images/webclip_${environment.api.webshopId}.jpeg`);
  }

  ngOnInit() {
    this.route.queryParamMap
      .pipe(
        filter((params: ParamMap) => params.has('redirect') || params.has('reset-password') || params.has('request-password')),
        map((params: ParamMap) => {
          if (params.has('redirect')) {
            this.dialog
              .open(LoginModalComponent, { data: { forgotPassword: false } })
              .afterClosed()
              .pipe(
                tap((result: string | null) => {
                  if (result === 'success') {
                    this.router.navigate([decodeURIComponent(params.get('redirect')!)]);
                  }
                }),
                untilDestroyed(this),
              )
              .subscribe();
          }
          if (params.has('reset-password')) {
            this.dialog
              .open(PasswordResetModalComponent, {
                width: '400px',
                data: {
                  guiId: params.get('reset-password'),
                },
              })
              .afterClosed()
              .pipe(
                tap((result: string | null) => {
                  if (result === 'success') {
                    this.router.navigate([decodeURIComponent(params.get('redirect')!)]);
                  }
                }),
                untilDestroyed(this),
              )
              .subscribe();
          }
          if (params.has('request-password')) {
            this.dialog
              .open(LoginModalComponent, {
                width: '400px',
                data: {
                  forgotPassword: true,
                },
              })
              .afterClosed()
              .pipe(untilDestroyed(this))
              .subscribe();
          }
        }),
        untilDestroyed(this),
      )
      .subscribe();

    if (this.isBrowser) {
      DomHelperService.fallback100vh(this.document);
      DomHelperService.removeMobileNavHeight(this.document);

      fromEvent(window, 'resize')
        .pipe(
          tap(() => {
            DomHelperService.fallback100vh(this.document);
            DomHelperService.removeMobileNavHeight(this.document);
          }),
          untilDestroyed(this),
        )
        .subscribe();
    }
  }
}
