import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { injectInfiniteQuery, injectQuery, keepPreviousData, queryOptions } from '@ngneat/query';
import { Accessory, Article, ArticleFacetedSearchResponse, PriceQueryInterface, StockAvailability } from '@lobos/common-v3';
import { GlobalStore } from '../../store/global.store';
import { map } from 'rxjs/operators';
import { GroupByPipe } from '../../pipes/group-by.pipe';
import { SuedoArticle } from '../../interfaces/suedo-article.interface';
import { SuedoPrice } from '../../interfaces/suedo-price.interface';
import { FacetParams } from './category.service';
import { catchError } from 'rxjs';
import { isArray } from '../../pipes/utils/utils';

@Injectable({ providedIn: 'root' })
export class ArticleService {
  _http = inject(HttpClient);
  _query = injectQuery();
  _infiniteQuery = injectInfiniteQuery();
  store = inject(GlobalStore);

  getArticleOptions = (id: string) =>
    queryOptions({
      queryKey: ['article', id] as const,
      queryFn: () => {
        return this._http.get<SuedoArticle>(`/api/articles/${encodeURIComponent(id)}`);
      },
      retry: false,
    });

  getArticle(id: string) {
    return this._query(this.getArticleOptions(id));
  }

  getPrice(params: PriceQueryInterface) {
    return this._query({
      queryKey: ['article', 'price', params, this.store.isLoggedIn()] as const,
      queryFn: () => {
        return this._http.get<SuedoPrice>(`/api/price`, { params: params as any });
      },
    });
  }

  getAvailability(articleId: string | number) {
    return this._query({
      queryKey: ['article', 'availability', articleId, this.store.isLoggedIn()] as const,
      queryFn: () => {
        return this._http.get<StockAvailability>(`/api/stock-availability/${encodeURIComponent(articleId)}`);
      },
    });
  }

  getArticleReplacedBy(articleId: string | number) {
    return this._query({
      queryKey: ['article', 'replacedBy', articleId] as const,
      queryFn: () => {
        return this._http.get<SuedoArticle[]>(`/api/articles/replace-by/${encodeURIComponent(articleId)}`);
      },
    });
  }

  getArticlesByIds(article: SuedoArticle) {
    return this._query({
      queryKey: ['article', 'replacedBy', article.sArticleID] as const,
      queryFn: () => {
        return this._http.get<SuedoArticle[]>(
          `/api/articles?ids=${article.oAccessories.map((a) => encodeURIComponent(a.sArticleID)).join(',')}`,
        );
      },
    });
  }

  getArticleByIdOrEan(idOrEan: string) {
    return this._query({
      queryKey: ['article', 'byIdOrEan', idOrEan] as const,
      queryFn: () => {
        return this._http.get<SuedoArticle | undefined>(`/api/articles/${idOrEan}`).pipe(
          catchError((err) => {
            if (err.error.status === 404) {
              return this._http.get<SuedoArticle>(`/api/articles/ean/${idOrEan}`).pipe(
                map((article: any) => {
                  if (isArray(article) && !article.length) {
                    return undefined;
                  } else {
                    return article;
                  }
                }),
              );
            }
            throw err;
          }),
        );
      },
    });
  }

  getArticleAccessories(article: Article) {
    return this._query({
      queryKey: ['article', 'accessories', article.sArticleID] as const,
      queryFn: () => {
        return this._http
          .get<SuedoArticle[]>(`/api/articles?ids=${article.oAccessories.map((a) => encodeURIComponent(a.sArticleID)).join(',')}`)
          .pipe(
            map((articles: SuedoArticle[]) => {
              const accessories: any[] = [];
              articles.forEach((accessoryArticle) => {
                const foundArticle = article.oAccessories.find(
                  (accessory: Accessory) => accessoryArticle.sArticleID === accessory.sArticleID,
                );
                if (foundArticle) {
                  accessories.push({
                    sAccessoriesType: foundArticle.sAccessoriesType,
                    oArticle: accessoryArticle,
                  });
                }
              });
              return accessories;
            }),
            map((accessories) => {
              const groups = new GroupByPipe().transform(accessories, 'sAccessoriesType');
              return {
                sArticleID: article.sArticleID,
                accessories: groups,
              };
            }),
          );
      },
    });
  }

  getArticleSearchResult(searchTerm: string, facets: FacetParams[]) {
    return this._infiniteQuery({
      queryKey: ['article', 'search-result', searchTerm, facets] as const,
      queryFn: ({ pageParam }) => {
        return this.fetchArticleSearchResult(searchTerm, pageParam, facets);
      },
      initialPageParam: 0,
      getPreviousPageParam: (firstPage) => firstPage.previousId,
      getNextPageParam: (lastPage) => lastPage.nextId,
      placeholderData: keepPreviousData,
    });
  }

  fetchArticleSearchResult(searchTerm: string, params: any, facets: FacetParams[]) {
    return this._http
      .post<ArticleFacetedSearchResponse>('/api/articles/search', {
        q: searchTerm,
        intPageIndex: params,
        intPageSize: 16,
        facets,
        sort: [],
      })
      .pipe(
        map((data: ArticleFacetedSearchResponse) => {
          return {
            data: data,
            nextId: data.articleTotal > (params + 1) * 9 ? params + 1 : null,
            previousId: params === 0 ? null : params - 1,
          };
        }),
      );
  }
}
