import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { injectMutation, injectQuery, keepPreviousData } from '@ngneat/query';
import { PaginationResponseInterface, SalesRepCustomer, User } from '@lobos/common-v3';
import { Router } from '@angular/router';
import { ErrorHandlerService } from '../error/error-handler.service';
import { SortConfig } from '../../interfaces/sort-config.interface';
import { UrlHelperService } from '../url/url-helper.service';

@Injectable({ providedIn: 'root' })
export class SalesRepService {
  _http = inject(HttpClient);
  _query = injectQuery();
  _mutation = injectMutation();
  router = inject(Router);
  errorHandler = inject(ErrorHandlerService);
  urlHelper = inject(UrlHelperService);

  getCustomers(page: number, size = 12, sort: SortConfig | null = null) {
    const params = new URLSearchParams({
      intPageIndex: page.toString(),
      intPageSize: size.toString(),
    });
    if (sort) {
      params.set('sOrderBy', sort.column);
    }
    return this._query({
      queryKey: ['sales-rep-customers', params.toString()] as const,
      queryFn: () => {
        return this._http.get<PaginationResponseInterface<SalesRepCustomer>>(`/api/sales-rep/customers?${params.toString()}`);
      },
      placeholderData: keepPreviousData,
    });
  }

  loginWithCustomer() {
    return this._mutation({
      onSuccess: () => {
        return this.router.navigate(['/']).then(() => window.location.reload());
      },
      onError: () => {
        this.errorHandler.handleError({
          label: 'sales-rep.login-error',
          translate: true,
          translateScope: 'profile',
          name: 'Sales rep login error',
          message: 'Something went wrong while logging in with the customer',
          display: true,
        });
      },
      mutationFn: (customerId: number) => this._http.put<User>(`/api/sales-rep/${customerId}`, {}),
    });
  }

  logoutFromCustomer() {
    return this._mutation({
      onSuccess: () => {
        this.router.navigate([this.urlHelper.getUrlPath('/sales-rep')]).then(() => window.location.reload());
      },
      onError: () => {
        this.errorHandler.handleError({
          label: 'sales-rep.logout-error',
          translate: true,
          translateScope: 'profile',
          name: 'Sales rep logout error',
          message: 'Something went wrong while logging out from the customer',
          display: true,
        });
      },
      mutationFn: (body: unknown) => this._http.post<User>(`/api/sales-rep/reset`, body),
    });
  }
}
